import axios from 'axios';
import { apiUrl} from'../Config/Config';

const mem_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"membership/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['mem_cat_id'], label: planlist[i]['category'] };
            }
            
        }       
    });
    return planLists;
}

const domainList = () => {
    var qs = require('qs');

    let domainlist = [];
    var postobject = {
admin_id : localStorage.getItem('admin_id')

    };

        axios.post(apiUrl+"user/domainlist",qs.stringify(postobject)).then(res => {

        if(res.data.status == "success"){
            let i=0;
            let domainlists = res.data.domainlist;
            
            for(i; i<domainlists.length; i++){
                domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
            }
            
        }       
    });
   
    return domainlist;
}

const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}
const adcategories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"advertisement/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}
const year_list = () => {

    let years = [];  
    let start_yr = 1950;
    let end_yr = new Date().getFullYear();
    let diff = end_yr - start_yr;
    // console.log(end_yr, 'end_yr');
    // console.log(diff, 'diff');
    let i=0;
    for(i; i<=diff; i++){
        let opt = start_yr + i;
        years[i] = { value: opt, label: opt };
    }
    return years;
}
export const lang = {

    common: {
        title:'We-Rehab',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        status_filter_option:[
            { value: '', label: 'All' },
            { value: 'A', label: 'Active' },
            { value: 'I', label: 'Inactive' },
        ],
        advertisement_linktype:[
            { value: 'external', label: 'External' },
            { value: 'internal', label: 'Internal' },
            { value: 'offer', label: 'Offer' },
        ],
        categories_option: categories(),
        categories_option1: categories1(),
        adcategories_option: adcategories(),
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' }
        ],
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
        //  field_options :[
        //     { value: 'text', label: 'Text' },
        //     { value: 'email', label: 'Email' },
        //     { value: 'tel', label: 'Tel' },
        //     { value: 'textarea', label: 'Textarea' },
        //     { value: 'password', label: 'Password' },
        //     { value: 'select', label: 'Select' },
        //     { value: 'checkbox', label: 'Checkbox' },
        //     { value: 'radio', label: 'Radio' },
        //     { value: 'url', label: 'url' },
        //     { value: 'image', label: 'Image' },
        // ],
        field_options :[
            { value: 'userMyApptSt', label: 'User`s Appointments'},
            { value: 'userApptResSt', label: 'User`s Accepted Appointments'},
            { value: 'userOngoingApptSt', label: 'User`s Ongoing Appointments'},
            { value: 'userInvoiceSt', label: 'User`s Invoices'},
            { value: 'userMyAdmissionSt', label: 'User`s Admissions'},
            { value: 'userPaymentHistorySt', label: 'User`s Payment Bills'},
            { value: 'userApptCompSt', label: 'User`s Completed Appointments'},
            { value: 'userInviteSt', label: 'User`s Invite Friends'},
            
            { value: 'rehabMembSt', label: 'Rehab`s Membership'},
            { value: 'rehabDriverListSt', label: 'Rehab`s Drivers'},
            { value: 'rehabNewApptSt', label: 'Rehab`s New Appointments'},
            { value: 'rehabComApptSt', label: 'Rehab`s Accepted Appointments'},
            { value: 'rehabOnGoingTripSt', label: 'Rehab`s Ongoing Trip'},
            { value: 'rehabOurPatSt', label: 'Rehab`s Admissions'},
            { value: 'rehabCompletedApptSt', label: 'Rehab`s Completed Appointments'},
            { value: 'rehabCancApptSt', label: 'Rehab`s Cancelled Appointments'},
            { value: 'rehabDisputeSt', label: 'Rehab`s User Disputes'},
            { value: 'rehabTransactionSt', label: 'Rehab`s Transaction History'},
            { value: 'rehabAcctSt', label: 'Rehab`s Account Management'},

            { value: 'driverNewApptSt', label: 'Driver`s Appointments'},
            { value: 'driverCompltApptSt', label: 'Driver`s Completed Appointments'},
            { value: 'driverCancApptSt', label: 'Driver`s Cancelled Appointments'},
            
            { value: 'companyRehabSt', label: 'Company`s Rehabs'},
            { value: 'companyUserSt', label: 'Company`s Users'},
            { value: 'companyDriverListSt', label: 'Company`s Drivers'},
            { value: 'companyInviteSt', label: 'Invited Rehabs'},
            { value: 'companyNewApptSt', label: 'Company`s New Appointments'},
            { value: 'companyAssApptSt', label: 'Company`s Assigned Appointments'},
            { value: 'companyObserSt', label: 'Company`s Admitted Appointments'},
            { value: 'companyCompletedSt', label: 'Company`s Completed Appointments'},
            { value: 'ownDriverListSt', label: 'Company`s Own Drivers'},
            { value: 'companyCommissionst', label: 'Company`s Transaction'},
            { value: 'companyDriverPaymentst', label: 'Company`s Driver Transaction'},
            { value: 'companyDisputeSt', label: 'Company`s User Disputes'},
            { value: 'companyCouponSt', label: 'Company`s Coupon'},
            { value: 'companyTransactionSt', label: 'Company`s Monthly Earnings'},
            { value: 'companyYearEarningSt', label: 'Company`s Yearly Earnings'},
            
        ],
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'flat', label: 'Flat' },
        ],
        // module_option:[
        //     { value: 'module1', label: 'Module1 (Doctor Listing)' },
        //     { value: 'module2', label: 'Module2 (Product Listing)' },
        // ],
        // productType_option:[
        //     { value: '1', label: 'Normal Product' },
        //     { value: '2', label: 'Shopping Product' },
        //     { value: '3', label: 'Regular Product' },
        // ],
        period_option: [
            // { value: 'Day', label: 'Day' },
            { value: 'Month', label: 'Month' },
            { value: 'Year', label: 'Year' }
        ],
        contentType_option:[
            { value: 'description', label: 'Description' },
            { value: 'form', label: 'Form' },
            { value: 'listing', label: 'Listing' },
            { value: 'membership', label: 'Membership' },
            
        ],
        domainList_option:domainList(),
        mem_plan_option: mem_plan(),
        year_option: year_list(),
        

    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
};


